body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__input-container input {
	width: 100%;
	padding: 10px;
	margin-bottom: 8px;
	box-sizing: border-box;
	border-radius: 2px;
	border: solid 1px #c0c3cb;
	font-family: inherit;
	font-size: 15px;
}

@media print {
	body {
		zoom: 5;
	}
	.hideOnPrint {
		display: none;
	}
	.printableContainer {
		max-width: 100% !important;
	}
}

.option-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	position: relative;
	right: 0;
	bottom: 0;
	left: 0;
	height: 30px;
	width: 30px;
	transition: all 0.15s ease-out 0s;
	background: #cbd1d8;
	border: none;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	margin-right: 0.5rem;
	outline: none;
	position: relative;
	z-index: auto;
  }
  .option-input:hover {
	background: #9faab7;
  }
  .option-input:checked {
	background: #0CB504;
  }
  .option-input:checked::before {
	width: 40px;
	height: 40px;
	display:flex;
	content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 30 30"%3E%3Cpath fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m4 12l6 6L20 6"%2F%3E%3C%2Fsvg%3E');
	font-size: 25px;
	font-weight:bold;
	position: absolute;
	right: -7px;
    top: 4px;
	justify-content:center;
  }
  .option-input:checked::after {
	-webkit-animation: click-wave 0.35s;
	-moz-animation: click-wave 0.35s;
	animation: click-wave 0.35s;
	background: #0CB504;
	content: '';
	display: block;
	position: relative;
	z-index: auto;
  }
  .option-input.radio {
	border-radius: 50%;
  }
  .option-input.radio::after {
	border-radius: 50%;
  }
  
  @keyframes click-wave {
	0% {
	  height: 40px;
	  width: 40px;
	  opacity: 0.35;
	  position: relative;
	}
	100% {
	  height: 100px;
	  width: 100px;
	  margin-left: -40px;
	  margin-top: -40px;
	  opacity: 0;
	}
  }
  
